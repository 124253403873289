* {
  box-sizing: border-box;
}

html {
  font-family: 'Vulf Sans', sans-serif;
  font-size: 16px;
  background: cornsilk;
  color: #111;
}

body {
  margin: 0;
  padding: 1rem;
}

nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

nav ul li {
  display: inline-block;
  margin-right: 1rem;
}


a {
  color: inherit;
}

a:hover {
  color: indianred;
}

hr {
  margin: 2rem 0;
  height: 1px;
  background-color: rgba(0,0,0,0.25);
  border: 0;
}

footer {
  margin-bottom: 2rem;
  font-size: 80%;
}

footer nav {
  margin-bottom: 1rem;
}

.recipe {
  margin-bottom: 1rem;
}

.step {
  padding-bottom: 2rem;
}

h1, h2, ul, ol {
  margin: 0;
}

h1 {
  margin-top: 3rem;
  margin-bottom: 1rem;
  font-size: 2.5rem;
}

h2 {
  font-size: 1.2rem;
  padding-bottom: 0.2rem;
}

p {
  margin: 0;
}

.ingredients {
  padding: 0;
  margin: 0;
  list-style: none;
}

.ingredients li {
  font-family: 'Vulf Mono', monospace;
  padding: 0;
  padding-left: 2rem;
  text-indent: -2rem;
  margin-bottom: 0.3rem;
  hyphens: auto;
}

.directions p {
  margin-bottom: 1rem;
  font-size: 1.2em;
}

.recipe-table .step td {
  vertical-align: top;
}

@media only screen and (max-width: 768px) {
  .ingredients {
    overflow-x: scroll;
  }
  .ingredients li {
    white-space: nowrap;
  }
}

@media only screen and (min-width: 768px) {

    html {
      font-size: 20px;
    }

    body {
        padding: 2rem 1rem;
        max-width: 54rem;
        margin: 0 auto;
    }

    .recipe {
        margin-bottom: 1rem;
    }

    .step {
        display: grid;
        grid-template-columns: 20rem 1fr;
        grid-column-gap: 1rem;
        background: none !important;;
    }

    .step h2 {
        grid-column: 1 / -1;
    }

    .directions p {
        margin: 0 0 1rem 0;
    }
}
