@font-face {
    font-family: "Vulf Sans";
    font-style: normal;
    font-weight: 400;
    src: url("/assets/fonts/Vulf_Sans-Regular.woff2") format("woff2");
}

@font-face {
    font-family: "Vulf Sans";
    font-style: normal;
    font-weight: 700;
    src: url("/assets/fonts/Vulf_Sans-Bold.woff2") format("woff2");
}

@font-face {
    font-family: "Vulf Mono";
    font-style: normal;
    font-weight: 400;
    src: url("/assets/fonts/Vulf_Mono-Regular_web.woff2") format("woff2");
}